<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Om oss
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            Europower AS er Norges ledende nyhets- og analysebyrå om og for kraftbransjen. Selskapet utgir nettstedet <a href="https://europower.no">europower.no</a>, magasinet Europower og kundemagasinet Vår Energi.
          </p>
          <p>
            Europower er eid av mediekonsernet DN Media Group AS, med søsterselskaper som Dagens Næringsliv, Upstream, Trade Winds, Recharge, IntraFish Media, My Newsdesk m.fl.
          </p>
          <p>
            <strong>Historikk</strong>
            <ul>
              <li>
                2001: Europower AS blir etablert gjennom sammenslåing av nyhetsbyrået TDN Kraft AS og analyseselskapet Energi Analyse AS.
              </li>
              <li>
                2005: Europower kjøper aksjene i Energi Forlag AS, utgiver av bransjebladet Energi og kundebladet Vår Energi. I tillegg utgir forlaget bransjebøker og oppslagsverk.
              </li>
              <li>
                2007: Europower kjøper aksjene i Intstream OY, et selskap som leverer software/beslutningsstøttesystemer for krafthandel og analyse.
              </li>
              <li>
                2012: Bladet Energi relanseres i nytt format og design, og slås sammen med avisen Europower.
              </li>
              <li>
                2016: Europower selger Intstream OY til finske Enegia Group.
              </li>
              <li>
                2019: Europower lanserer nytt nettsted <a href="https://europower.no">europower.no</a>. Innholdet fra magasinet digitaliseres og gjøres tilgjengelig på samme plattform.
              </li>
            </ul>
          </p>
          <p>
            Europower er en komplett nyhets- og informasjonsleverandør for kraftbransjen på nordisk nivå. Fra digital nyhetstjeneste med databaseløsninger på selskaps- og bransjenivå, månedsmagasin, kundemagasin for kraftselskaper, rapporter og analyser om kraftbransjen, dekker virksomheten de fleste behov for kraftrelatert informasjon.
          </p>
          <p>
            Europowers redaksjon utøver en uavhengig, etterrettelig og aktuell journalistikk om og for aktører i og rundt kraftbransjen.
          </p>
          <p>
            Europowers Energy Intelligence selskapsdatabase er en nettbasert database, utviklet av vår analyseavdeling. Den gir oversikt og dybdeinformasjon på alle kraftselskaper i Norge. På en enkel måte kan du innhente bl.a. opplysninger om eierstrukturer, M&A historikk, regnskaps- og nøkkeltall, nett- og kraftstasjonsinformasjon.
          </p>
          <p>
            Europower kan tilby skreddersydde nyhets-, database- og analyseløsninger etter dine og ditt selskaps behov.
          </p>
          <p>
            Selskapet har sitt hovedkontor i Oslo.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'aboutus-page',
  extends: Page
};
</script>
